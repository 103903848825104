import React from 'react'
import { AdminBox, I18nHtml, SharingGridButton, findApiV4Link } from 'shared'
import { ManageContentProps, StateMessageProps } from './types'

import IconRocket from '@betterplace/assets/images/icons/rocket_purple.svg'

export const ManageContent = ({
  showManageContent,
  manageUrl,
  manageLiveWidgetsUrl,
  fundraisingEvent,
  showWelcomeMessage,
  firstName,
  category,
}: ManageContentProps): JSX.Element | null => {
  let shareUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'platform' }) ?? ''
  shareUrl += '?utm_campaign=creator_share&utm_medium=admin_box&utm_source=Link'
  if (showManageContent === 'false') return null
  return (
    <div className="container">
      <div className="row preserves-space-for-transparent-header pb-4">
        <div className="col mt-4">
          <AdminBox>
            <div className="container">
              <span>
                {showWelcomeMessage === 'true' ? (
                  <>
                    <img src={IconRocket} alt="" />
                    <span className="text-lg font-bold block mt-4">
                      {i18n.t('fundraising_events.admin_boxes.show.recent_registration_headline')}
                    </span>
                    <span>{i18n.t('fundraising_events.admin_boxes.show.recent_registration_notice')}</span>
                  </>
                ) : (
                  <>
                    <span className="text-lg font-bold block">
                      {i18n.t('fundraising_events.admin_boxes.show.default_headline_html', { name: firstName })}
                    </span>
                    <StateMessage fundraisingEvent={fundraisingEvent} />
                  </>
                )}
              </span>
              <div className="flex flex-wrap gap-4 items-center justify-center mt-4">
                <a className="btn btn-large btn-outline-green" href={manageUrl}>
                  {i18n.t('fundraising_events.admin_boxes.show.manage_area_link')}
                </a>
                {category === 'live_streams' ? (
                  <a className="btn btn-large btn-primary" href={manageLiveWidgetsUrl}>
                    {i18n.t('fundraising_events.admin_boxes.show.manage_area_live_widgets_link')}
                  </a>
                ) : (
                  <SharingGridButton
                    shareUrl={shareUrl}
                    platforms={['facebook', 'twitter', 'link', 'whatsapp', 'messenger', 'instagram']}
                    modalHeader={i18n.t('fundraising_events.show.basic_information.share_modal_headline')}
                    buttonLabel={i18n.t('fundraising_events.admin_boxes.show.share_button_label')}
                    size="large"
                    variant="primary"
                    utmMedium="fepp_admin_box"
                  />
                )}
              </div>
            </div>
          </AdminBox>
        </div>
      </div>
    </div>
  )
}

const StateMessage = ({ fundraisingEvent }: StateMessageProps) => {
  let i18nKey = 'active_notice_html'
  if (fundraisingEvent.closed_at) i18nKey = 'closed_notice_html'
  else if (fundraisingEvent.donations_prohibited) {
    if (fundraisingEvent.activate_donations_at) i18nKey = 'scheduled_notice_html'
    else i18nKey = 'blocked_notice_html'
  }
  return <I18nHtml as="p" i18nKey={['fundraising_events.admin_boxes.show', i18nKey]} />
}
